import Navbar from "../../utils/navbar";
import RxnForm from "../../utils/reaction/form";

function Reaction(){
    return (  
        <>
            <Navbar
                component={<RxnForm />}
                tag={"rxn"}
            />
        </>
    );
}
 
export default Reaction;