export const NavLinks = [
    {title : "", label : "Home" , url: "/" , hasIcon:true, tag:"home"},
    {title : "Setup: My first optimization", label : "Set up campaign" , url: "/optimizer" , hasIcon:false, tag:"cam"},
    {title : "Target parameters: My first optimization", label : "Target parameters" , url: "/target_params" , hasIcon:false, tag:"tar"},
    {title : "Reaction parameters: My first optimization", label : "Reaction parameters" , url: "/rxn_params" , hasIcon:false, tag:"rxn"},
    {title : "Design experiments: My first optimization", label : "Design experiments" , url: "/design_exp" , hasIcon:false, tag:"exp"},
    {title : "Results", label : "Results" , url: "/results" , hasIcon:false, tag:"res"},
    {title : "AI Insights", label : "AI insights" , url: "/insights" , hasIcon:false, tag:"ins"},
]

export const Campaign_Table_Headers = [
    "Dataset name",
    "Source",
    "Selected"
]

export const Target_Table_Headers = [
    "Target name",
    "Method",
    "Range (separated by semicolon ; )",
    "Target",
    "Relative importance (%)",
    "Action"
]

export const Reaction_Table_Headers = [
    "Parameter name [units]",
    "Parameter type",
    "Parameter values/ range (separated by semicolon ; )",
    "Action"
]

export const Exp_Table_Headers = [
    "Select parameter",
    "Continuous parameter value",
    "Categorical parameter value",
    "Action"
]

