import Navbar from "../../utils/navbar";
import ResComp from "../../utils/results";

function Result(){
    return (  
        <>
            <Navbar
                component={<ResComp />}
                tag={"res"}
            />
        </>
    );
}
 
export default Result;