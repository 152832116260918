import plot from "../../assets/plot.png"

const InsightPlot = () => {
    return (  
        <div className="container py-5 my-5">
            <h3 className="text-decoration-underline fs-4">
                Parameter space
            </h3>
            <img
                src={plot}
                className="img-fluid rounded-3 shadow-sm"
            />
        </div>
    );
}
 
export default InsightPlot;