import { useState } from "react";
import { NavLinks } from "../constants";

function Navbar({component , tag , onTabChange }) {

    const [open, setOpen] = useState(true)

    const handleTabClick = (value) => {
        onTabChange(value);
    };

    return (  
        <>
            <div className="bg-white border px-3 py-2  d-flex justify-content-between align-items-center gap-3">
                <div className="d-flex gap-3 justify-content-start align-items-center">
                    <div id="menu-icon" onClick={()=> setOpen(!open)}>
                        <i className="fa fa-fw fa-bars"></i>
                    </div>
                    <div>
                        <h3 className="fs-4">
                            {NavLinks.map((item, index) => (
                                item.tag == tag ? item.title : ""
                            ))}
                        </h3>
                    </div>
                </div>
                <button className="btn btn-outline-success-dark rounded-pill px-3">
                    Log out
                </button>
            </div>
            <div className="wrapper d-flex align-items-stretch">
                <div id="sidebar" className={open ? "" : 'active'}>
                    <div className="p-5 d-flex flex-column gap-5 justify-content-center align-items-center">
                        {NavLinks.map((item, index) => (
                            <div key={index}>
                                {item.tag == "home" ?
                                    <a href={item.url}  className={`${item.tag == tag ? '' : 'text-decoration-none'} text-primary fw-semibold m-0`} onClick={()=> handleTabClick(item.tag)}>
                                        <i class="fa fa-fw fa-home"></i>
                                        <span>{item.label}</span>
                                    </a>
                                    :
                                    <p className={`${item.tag == tag ? 'text-decoration-underline' : 'text-decoration-none'} text-primary fw-semibold m-0`} onClick={()=> handleTabClick(item.tag)} style={{ cursor : "pointer" }}>
                                        {item.hasIcon && <i class="fa fa-fw fa-home"></i>}
                                        <span>{item.label}</span>
                                    </p>
                                }
                            </div>
                        ))}
                    </div>                
                </div>
                <div className="container py-5"  id="content">
                    {component}
                </div>
            </div>
        </>
    );
}
 
export default Navbar;