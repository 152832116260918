function RouteFinder() {
    return (
        <div>
            <h1>Route Finder</h1>
            <p>This component is used to find the best route based on user preferences and current location.</p>
            <p>Coming soon...</p>
        </div>
    )
}

export default RouteFinder;