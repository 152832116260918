import { useState } from "react";
import plot from "../../assets/plot.png"

const ResComp = () => {
    const [Form, setForm] = useState({
        campaignName: "",
        campaignDescription: "",
        campaignSelected: "",
      });
    
    const handleChange = (e) => {
        setForm({ ...Form, [e.target.name]: e.target.value });
    };
    
    const handleCampaignChange = (e) => {
        setForm({ ...Form, campaignSelected: e.target.value });
    };

    const handleSelect = (e) => {
        setForm({ ...Form, method: e.target.value });
    };
    
    const HandleSubmit = (e) => {
        e.preventDefault();
        console.log("Form submitted: ", Form);
    };

    return (  
        <>
            <div className="row">
                <div className="col-12 py-3">
                    <div className="d-flex justify-content-start align-items-center gap-3">
                        <h3 className="text-decoration-underline fs-4">
                            Learning Curve
                        </h3>
                        <div className="bg-success-dark rounded-pill ">
                            <i className="fa fa-fw fa-info text-white"></i>
                        </div>
                    </div>
                </div>
                <div className="col-12 py-3">
                    <img
                        src={plot}
                        className="img-fluid rounded-3 shadow-sm"
                    />
                </div>
            </div>

            <form onSubmit={HandleSubmit}>
                <div className="row border-bottom">
                    <div className="col-12 py-3">
                        <div className="d-flex justify-content-start align-items-center gap-3">
                            <h3 className="text-decoration-underline fs-4">
                                Pareto plot
                            </h3>
                            <div className="bg-success-dark rounded-pill ">
                                <i className="fa fa-fw fa-info text-white"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 py-3">
                        <div className="d-flex justify-content-start align-items-center gap-5">
                            <p className="text-dark text-nowrap m-0">Objective 1:</p>
                            <div className="text-center" style={{ width: "100%" }}>
                                <select onChange={handleSelect} className="form-select">
                                    <option value="maximize">Maximize</option>
                                    <option value="minimize">Minimize</option>
                                </select>  
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 py-3">
                        <div className="d-flex justify-content-start align-items-center gap-5">
                            <p className="text-dark text-nowrap m-0">Objective 2:</p>
                            <div className="text-center" style={{ width: "100%" }}>
                                <select onChange={handleSelect} className="form-select">
                                    <option value="maximize">Maximize</option>
                                    <option value="minimize">Minimize</option>
                                </select>  
                            </div>
                        </div>
                    </div>
                    <div className="col-12 py-3 text-center">
                        <button type="button" className="btn btn-outline-success-dark rounded-pill px-3 mb-3">
                            Create pareto plot
                        </button>
                    </div>
                    <div className="col-12 py-3">
                        <img
                            src={plot}
                            className="img-fluid rounded-3 shadow-sm"
                        />
                    </div>
                </div>
            </form>
        </>
    );
}
 
export default ResComp;