import { useState } from "react";
import { Campaign_Table_Headers } from "../../constants";

const CampaignForm = () => {
  const [Form, setForm] = useState({
    campaignName: "",
    campaignDescription: "",
    campaignSelected: "",
  });

  const handleChange = (e) => {
    setForm({ ...Form, [e.target.name]: e.target.value });
  };

  const handleCampaignChange = (e) => {
    setForm({ ...Form, campaignSelected: e.target.value });
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted: ", Form);
  };

  return (
    <>
      <form onSubmit={HandleSubmit}>
        <div className="row border-bottom">
          <div className="col-lg-6 py-3">
            <div className="d-flex justify-content-start align-items-center gap-3">
              <h3 className="text-decoration-underline fs-4">
                Optimization campaign
              </h3>
              <div className="bg-success-dark rounded-pill ">
                <i className="fa fa-fw fa-info text-white"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-6 py-3"></div>
          <div className="col-lg-6 py-3">
            <div className="d-flex justify-content-start align-items-center gap-5">
              <p className="text-dark text-nowrap m-0">Define new campaign:</p>
              <div className="text-center" style={{ width: "100%" }}>
                <label className="form-label fw-bold">Campaign Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="campaignName"
                  value={Form.campaignName}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 py-3">
            <div className="d-flex justify-content-start align-items-center gap-5">
              <div className="text-center" style={{ width: "100%" }}>
                <label className="form-label fw-bold">Description</label>
                <textarea
                  name="campaignDescription"
                  className="form-control"
                  value={Form.campaignDescription}
                  onChange={handleChange}
                ></textarea>
              </div>
              <button
                type="submit"
                className="btn btn-outline-success-dark rounded-pill px-4"
              >
                Save
              </button>
            </div>
          </div>
          <div className="col-lg-6 py-3">
            <div className="d-flex justify-content-start align-items-center gap-5">
              <p className="text-dark text-nowrap m-0">Selected campaign:</p>
              <div className="text-center" style={{ width: "100%" }}>
                <select onChange={handleCampaignChange} className="form-select">
                  <option value="0" selected>
                    Select Campaign
                  </option>
                  <option value="1">Campaign 1</option>
                  <option value="2">Campaign 2</option>
                  <option value="3">Campaign 3</option>
                  <option value="4">Campaign 4</option>
                  <option value="5">Campaign 5</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-6 py-3">
            <p>Try creating your own campaign!</p>
          </div>
          <div className="col-12 py-3">
            <p>
              Info: Start by defining a name for a new optimization campaign,
              optionally add a comment, and click ‘Save’.
            </p>
          </div>
        </div>
      </form>

      <div className="row py-4">
        <div className="col-lg-6 py-3">
          <div className="d-flex justify-content-start align-items-center gap-3">
            <h3 className="text-decoration-underline fs-4">
              Initialization data
            </h3>
            <div className="bg-success-dark rounded-pill ">
              <i className="fa fa-fw fa-info text-white"></i>
            </div>
          </div>
        </div>
        <div className="col-lg-6 py-3"></div>
        <div className="col-12 py-3">
          <table class="table">
            <thead>
              <tr>
                {Campaign_Table_Headers.map((ele, index) => (
                    <th key={index+"_"+ele}>{ele}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder=""/>              
                </td>
                <td>
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder=""/>              
                </td>
                <td>
                    <input type="file" class="form-control" id="exampleFormControlInput1" placeholder=""/>              
                                  
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CampaignForm;
