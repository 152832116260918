import InsightPlot from "../../utils/insights/plot";
import Navbar from "../../utils/navbar";

function Insight() {
    return (  

        <Navbar
            component={<InsightPlot />}
            tag={"ins"}
        />
        
    );
}
 
export default Insight;