import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/style.css';
import Home from './components/home/home';
import Campaign from './components/optimizer/campaign';
import RouteFinder from './components/routeFinder/route_finder';
import Experiment from './components/optimizer/design_exp';
import Insight from './components/optimizer/insights';
import Result from './components/optimizer/results';
import Reaction from './components/optimizer/rxn_params';
import Target from './components/optimizer/target_params';
import Optimizer from './components/optimizer';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/optimizer"element={<Optimizer/>}></Route>
        <Route path="/route-finder"element={<RouteFinder/>}></Route>
      </Routes>
    </Router>
  );
}

export default App;
