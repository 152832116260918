import { useState } from "react";
import { Reaction_Table_Headers } from "../../constants";

const RxnForm = () => {
    const [Form, setForm] = useState({
        name: "",
        target: "",
        method: "",
        range: "",
        imp: "",
      });
    
    const handleChange = (e) => {
        setForm({ ...Form, [e.target.name]: e.target.value });
    };

    const handleSelect = (e) => {
        setForm({ ...Form, method: e.target.value });
    };

    const HandleSubmit = (e) => {
        e.preventDefault();
        console.log("Form submitted: ", Form);
    };
    return (  
        
        <>
            <form onSubmit={HandleSubmit}>
                <div className="row border-bottom">
                    <div className="col-12 py-3">
                        <div className="d-flex justify-content-start align-items-center gap-3">
                            <h3 className="text-decoration-underline fs-4">
                                Parameter space
                            </h3>
                            <div className="bg-success-dark rounded-pill ">
                                <i className="fa fa-fw fa-info text-white"></i>
                            </div>
                        </div>
                    </div>
                    <div className="row py-4">
                        <div className="col-12 py-3">
                            <table class="table border-none">
                                <thead>
                                    <tr>
                                        {Reaction_Table_Headers.map((ele, index) => (
                                            <th key={index+"_"+ele}>{ele}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input type="text" name="name" value={Form.name} onChange={handleChange} class="form-control" placeholder=""/>              
                                        </td>
                                        <td>
                                            <select onChange={handleSelect} className="form-select">
                                                <option value="" selected>
                                                    Select Method
                                                </option>
                                                <option value="continuos">Continuos</option>
                                                <option value="categrotical">Categrotical</option>
                                            </select>              
                                        </td>
                                        <td>
                                            <input type="text" name="range" value={Form.range} onChange={handleChange} class="form-control" placeholder=""/>              
                                        </td>
                                        <td>
                                            <button type="submit" className="btn btn-outline-success-dark rounded-pill px-3">
                                                Save
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <h3 className="fs-5 fw-semibold py-4">
                        Note: Once the optimization has been started, no further variables can be added.
                    </h3>
                </div>
            </form>
            
            <div className="row border-bottom">
                <div className="col-12 py-3">
                    <div className="d-flex justify-content-start align-items-center gap-3">
                        <h3 className="text-decoration-underline fs-4">
                            Example
                        </h3>
                    </div>
                </div>
                <div className="row py-4">
                    <div className="col-12 py-3">
                        <table class="table border-none">
                            <thead>
                                <tr>
                                    {Reaction_Table_Headers.map((ele, index) => (
                                        <th key={index+"_"+ele}>{ele}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Solvent
                                    </td>
                                    <td>
                                        Categorical
                                    </td>
                                    <td>
                                        1,4-Dioxane; Heptane; Methanol; Water
                                    </td>
                                    <td>
                                        
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default RxnForm;