import { useState } from "react";
import { Exp_Table_Headers, Target_Table_Headers } from "../../constants";

const ExpComp = () => {
    const [Form, setForm] = useState({
        campaignName: "",
        campaignDescription: "",
        campaignSelected: "",
      });
    
    const handleChange = (e) => {
        setForm({ ...Form, [e.target.name]: e.target.value });
    };
    
    const handleCampaignChange = (e) => {
        setForm({ ...Form, campaignSelected: e.target.value });
    };

    const handleSelect = (e) => {
        setForm({ ...Form, method: e.target.value });
    };
    
    const HandleSubmit = (e) => {
        e.preventDefault();
        console.log("Form submitted: ", Form);
    };
    
    return (  
        <>
            <form onSubmit={HandleSubmit}>
                <div className="row border-bottom">
                    <div className="col-12 py-3">
                        <div className="d-flex justify-content-start align-items-center gap-3">
                            <h3 className="text-decoration-underline fs-4">
                                Optimization design
                            </h3>
                            <div className="bg-success-dark rounded-pill ">
                                <i className="fa fa-fw fa-info text-white"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 py-3">
                        <div className="d-flex justify-content-start align-items-center gap-5">
                            <p className="text-dark text-nowrap m-0">Optimisation approach:</p>
                            <div className="text-center" style={{ width: "100%" }}>
                                <select onChange={handleSelect} className="form-select">
                                    <option value="initialize">Initialize</option>
                                    <option value="optimize">Optimize</option>
                                    <option value="optimize-T">Optimize (Transfer Learning)</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6"></div>
                    <div className="col-lg-6 py-3">
                        <div className="d-flex justify-content-start align-items-start gap-5">
                            <p className="text-dark text-nowrap m-0">Number of experiments:</p>
                            <div className="text-center" style={{ width: "100%" }}>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="campaignName"
                                    value={Form.campaignName}
                                    onChange={handleChange}
                                />
                                <button
                                    type="submit"
                                    className="btn btn-outline-success-dark rounded-pill px-4 mt-3"
                                >
                                    Design Experiments
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6"></div>
                    <div className="col-12 py-3">
                        <div className="d-flex justify-content-start align-items-center gap-3">
                            <h3 className="text-decoration-underline fs-4">
                                Blocking of variables
                            </h3>
                            <div className="bg-success-dark rounded-pill ">
                                <i className="fa fa-fw fa-info text-white"></i>
                            </div>
                        </div>

                        <div className="row py-4">
                            <div className="col-12 py-3">
                                <table class="table border-none">
                                    <thead>
                                        <tr>
                                            {Exp_Table_Headers.map((ele, index) => (
                                                <th key={index+"_"+ele}>{ele}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <select onChange={handleSelect} className="form-select">
                                                    <option value="maximize">Maximize</option>
                                                    <option value="minimize">Minimize</option>
                                                </select>              
                                            </td>
                                            <td>
                                                <input type="text" name="target" value={Form.target} onChange={handleChange} class="form-control" placeholder=""/>              
                                            </td>
                                            <td>
                                                <select onChange={handleSelect} className="form-select">
                                                    <option value="maximize">Maximize</option>
                                                    <option value="minimize">Minimize</option>
                                                </select>              
                                            </td>
                                            <td>
                                                <button type="submit" className="btn btn-outline-success-dark rounded-pill px-3">
                                                    Add
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 py-3">
                        <div className="d-flex justify-content-start align-items-center gap-3">
                            <h3 className="text-decoration-underline fs-4">
                                Design Custom Experiements
                            </h3>
                            <div className="bg-success-dark rounded-pill ">
                                <i className="fa fa-fw fa-info text-white"></i>
                            </div>
                        </div>

                    </div>
                    <div className="py-3 text-center col-12">
                        <button type="button" className="btn btn-outline-success-dark rounded-pill px-3 mb-3">
                            Design Custom Experiments
                        </button>
                    </div>

                    <div className="col-12 pt-3 pb-3 text-center border-top border-bottom">
                        <div className="d-flex justify-content-center align-items-center gap-3">
                            <button type="button" className="btn btn-outline-success-dark rounded-pill px-3 my-3">
                                Send to Liquid Handler
                            </button>
                            <button type="button" className="btn btn-outline-success-dark rounded-pill px-3 my-3">
                                Downlaod as csv
                            </button>
                            <button type="button" className="btn btn-outline-success-dark rounded-pill px-3 my-3">
                                Print
                            </button>
                            <button type="button" className="btn btn-outline-success-dark rounded-pill px-3 my-3">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}
 
export default ExpComp;