import logo from "../../assets/ReactWise logo full.png";
import optimizer_logo from "../../assets/Closed_loop_v3.svg"
import search_logo from "../../assets/XAI.svg"
import { Link } from "react-router-dom";

function Home() {
    return (  
        <div className="container py-5">
            <div className="text-center ">
              <img src={logo} alt="Logo" className="img-fluid" width={800}/>
            </div>
            <div className="row py-5">
                <div className="col-md-6 text-center px-5 py-3">
                    <Link to="/optimizer" className="logo text-center text-decoration-none">
                        <h1 className="text-dark fs-4">Optimizer</h1>
                        <img src={optimizer_logo} alt="Logo" className="img-fluid py-3" width={200}/>
                        <p className="fst-italic fs-5 text-dark">Optimize your chemical reactions</p>
                    </Link>
                </div>
                <div className="col-md-6 text-center px-5 py-3">
                    <Link to="/route-finder" className="logo text-center text-decoration-none">
                        <h1 className="text-dark fs-4">Route Finding</h1>
                        <img src={search_logo} alt="Logo" className="img-fluid py-3" width={185}/>
                        <p className="fst-italic fs-5 text-dark">Obtain insights into your reactions</p>
                    </Link>
                </div>
                <div className="col-md-6 text-center py-2">
                    <button type="button" class="btn btn-outline-success-dark" style={{ width: "100%" }}>About</button>
                </div>
                <div className="col-md-6 text-center py-2">
                    <button type="button" class="btn btn-outline-success-dark" style={{ width: "100%" }}>Contact Us</button>
                </div>
                <div className="col-12 text-center py-2">
                    <button type="button" class="btn btn-outline-success-dark" style={{ width: "100%" }}>Feedback Form</button>
                </div>
                <div className="col-md-6 text-center py-2">
                    <p className="text-dark fs-5">Logged in as: user_email_here</p>
                </div>
                <div className="col-md-6 text-center py-2">
                    <button type="button" class="btn btn-outline-success-dark rounded-pill px-4">Logout</button>
                </div>
            </div>
        </div>
    );
}
 
export default Home;