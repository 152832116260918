import Navbar from "../../utils/navbar";
import TargetForm from "../../utils/target/form";

function Target(){
    return (  
        <>
            <Navbar
                component={<TargetForm />}
                tag={"tar"}
            />
        </>
    );
}
 
export default Target;