import { useState } from "react";
import Navbar from "../../utils/navbar";
import RxnForm from "../../utils/reaction/form";
import CampaignForm from "../../utils/campaign/form";
import InsightPlot from "../../utils/insights/plot";
import ResComp from "../../utils/results";
import TargetForm from "../../utils/target/form";
import ExpComp from "../../utils/exp/comp";

const Optimizer = () => {

    const [activeTab, setActiveTab] = useState("cam"); // Initially show Campaign component

    const handleTabChange = (tabTag) => {
        setActiveTab(tabTag);
    };

    return (  
        <>
            <Navbar
                tag={activeTab}
                component={
                    activeTab === "rxn" && <RxnForm/> ||
                    activeTab === "cam" && <CampaignForm/> ||
                    activeTab === "tar" && <TargetForm/> ||
                    activeTab === "exp" && <ExpComp/> ||
                    activeTab === "res" && <ResComp/> ||
                    activeTab === "ins" && <InsightPlot/> 
                }
                onTabChange={handleTabChange}
            />
        </>
    );
}
 
export default Optimizer;