import ExpComp from "../../utils/exp/comp";
import Navbar from "../../utils/navbar";

function Experiment(){
    return (  
        <>
            <Navbar
                component={<ExpComp />}
                tag={"exp"}
            />
        </>
    );
}
 
export default Experiment;