import { useState } from "react";
import { Target_Table_Headers } from "../../constants";

const TargetForm = () => {
    const [Form, setForm] = useState({
        name: "",
        target: "",
        method: "",
        range: "",
        imp: "",
      });
    
    const handleChange = (e) => {
        setForm({ ...Form, [e.target.name]: e.target.value });
    };

    const handleSelect = (e) => {
        setForm({ ...Form, method: e.target.value });
    };

    const HandleSubmit = (e) => {
        e.preventDefault();
        console.log("Form submitted: ", Form);
    };
    return (  
        
        <>
            <form onSubmit={HandleSubmit}>
                <div className="row border-bottom">
                    <div className="col-12 py-3">
                        <div className="d-flex justify-content-start align-items-center gap-3">
                            <h3 className="text-decoration-underline fs-4">
                                Target parameter
                            </h3>
                            <div className="bg-success-dark rounded-pill ">
                                <i className="fa fa-fw fa-info text-white"></i>
                            </div>
                        </div>
                    </div>
                    <div className="row py-4">
                        <div className="col-12 py-3">
                            <table class="table border-none">
                                <thead>
                                    <tr>
                                        {Target_Table_Headers.map((ele, index) => (
                                            <th key={index+"_"+ele}>{ele}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input type="text" name="name" value={Form.name} onChange={handleChange} class="form-control" placeholder=""/>              
                                        </td>
                                        <td>
                                            <select onChange={handleSelect} className="form-select">
                                                <option value="" selected>
                                                    Select Method
                                                </option>
                                                <option value="maximize">Maximize</option>
                                                <option value="minimize">Minimize</option>
                                            </select>              
                                        </td>
                                        <td>
                                            <input type="text" name="range" value={Form.range} onChange={handleChange} class="form-control" placeholder=""/>              
                                        </td>
                                        <td>
                                            <input type="text" name="target" value={Form.target} onChange={handleChange} class="form-control" placeholder=""/>              
                                        </td>
                                        <td>
                                            <input type="text" name="imp" value={Form.imp} onChange={handleChange} class="form-control" placeholder=""/>              
                                        </td>
                                        <td>
                                            <button type="submit" className="btn btn-outline-success-dark rounded-pill px-3">
                                                Save
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </form>

            <div className="row border-bottom">
                <div className="col-12 py-3">
                    <div className="d-flex justify-content-start align-items-center gap-3">
                        <h3 className="text-decoration-underline fs-4">
                            Example
                        </h3>
                        <div className="bg-success-dark rounded-pill ">
                            <i className="fa fa-fw fa-info text-white"></i>
                        </div>
                    </div>
                </div>
                <div className="row py-4">
                    <div className="col-12 py-3">
                        <table class="table border-none">
                            <thead>
                                <tr>
                                    {Target_Table_Headers.map((ele, index) => (
                                        <th key={index+"_"+ele}>{ele}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Yield
                                    </td>
                                    <td>
                                        Maximize
                                    </td>
                                    <td>
                                        0;100
                                    </td>
                                    <td>
                                        95
                                    </td>
                                    <td>
                                        70
                                    </td>
                                    <td>
                                        <button className="btn btn-outline-success-dark rounded-pill px-3">
                                            <i className="fa fa-fw fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default TargetForm;